<template>
    <div class="container-fluid">
        <h2>Permissions</h2>
        <div class="d-flex mb-2">
            <button type="button" class="btn bg-custom-color ms-auto" data-bs-toggle="modal"
                data-bs-target="#addPermission">
                Add Permission
            </button>
        </div>

        <!-- Modal for Adding Permission -->
        <div class="modal fade" id="addPermission" tabindex="-1" aria-labelledby="addPermissionLabel" aria-hidden="true"
            ref="addPermissionModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addPermissionLabel">Add Permission</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="resetForm"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="storePermission">
                            <div>
                                <label for="permission_name" class="text-lg font-medium">Permission Name</label>
                                <div class="my-3">
                                    <input type="text" placeholder="Enter Permission Name" v-model="permissionName"
                                        class="form-control" />
                                </div>
                                <label for="description" class="text-lg font-medium">Description</label>
                                <div class="my-3">
                                    <input type="text" placeholder="Enter Description" v-model="permissionDescription"
                                        class="form-control" />
                                </div>
                                <button type="submit" class="btn bg-custom-color">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal for Updating Permission -->
        <div class="modal fade" id="updatePermission" tabindex="-1" aria-labelledby="updatePermissionLabel"
            aria-hidden="true" ref="updatePermissionModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="updatePermissionLabel">Update Permission</h5>
                        <button type="button" class="btn-close" aria-label="Close"
                            @click="closeModal('updatePermission')"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="updatePermission">
                            <div>
                                <label for="permission_name" class="text-lg font-medium">Permission Name</label>
                                <div class="my-3">
                                    <input type="text" placeholder="Enter Permission Name" v-model="permissionName"
                                        class="form-control" />
                                </div>
                                <label for="description" class="text-lg font-medium">Description</label>
                                <div class="my-3">
                                    <input type="text" placeholder="Enter Description" v-model="permissionDescription"
                                        class="form-control" />
                                </div>
                                <button type="submit" class="btn bg-custom-color">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <table class="table table-striped table-bordered text-center">
            <thead class="bg-gray-50">
                <tr>
                    <th>#</th>
                    <th>Permission Name</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="permissions.length === 0">
                    <td colspan="4" class="text-center">No permissions found</td>
                </tr>
                <tr v-for="(permission, index) in permissions" :key="permission.permission_id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ permission.permission_name }}</td>
                    <td>{{ permission.description }}</td>
                    <td>
                        <button class="btn btn-primary button-spacing"
                            @click="editPermission(permission.permission_id)">Edit</button>
                        <button class="btn btn-danger"
                            @click="deletePermission(permission.permission_id)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            permissions: [],
            permissionName: "",
            permissionDescription: "",
            currentPermissionId: null
        };
    },
    mounted() {
        this.fetchPermissions();
    },
    methods: {
        async fetchPermissions() {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            try {
                const response = await axios.get("http://127.0.0.1:8000/api/permissions", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                this.permissions = response.data.status ? response.data.permissions : [];
            } catch (error) {
                console.error("Error fetching permissions:", error);
                this.permissions = [];
                this.handleAuthError(error);
            }
        },
        async storePermission() {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            try {
                const response = await axios.post(
                    "http://127.0.0.1:8000/api/permissions/store",
                    {
                        permission_name: this.permissionName,
                        description: this.permissionDescription,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                if (response.data.status) {
                    Swal.fire({ icon: "success", title: "Success", text: response.data.message });
                    this.fetchPermissions();
                    this.resetForm();
                } else {
                    Swal.fire({ icon: "error", title: "Error", text: response.data.message });
                }
            } catch (error) {
                console.error("Error storing permission:", error);
                Swal.fire({ icon: "error", title: "Error", text: "An error occurred while creating the permission." });
            }
        },
        async editPermission(permission_id) {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }

            try {
                const response = await axios.get(`http://127.0.0.1:8000/api/permissions/edit/${permission_id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data && response.data.status) {
                    this.permissionName = response.data.permission.permission_name;
                    this.permissionDescription = response.data.permission.description;
                    this.currentPermissionId = permission_id;
                    this.showModal("updatePermission");
                } else {
                    Swal.fire({ icon: "error", title: "Error", text: response.data.message || "An unexpected error occurred." });
                }
            } catch (error) {
                console.error("Error fetching permission for edit:", error);
                Swal.fire({ icon: "error", title: "Error", text: "An error occurred while fetching the permission." });
            }
        },
        showModal(modalId) {
            const modal = document.getElementById(modalId);
            if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
                document.body.classList.add("modal-open");

                const backdrop = document.createElement("div");
                backdrop.className = "modal-backdrop fade show";
                document.body.appendChild(backdrop);
            }
        },
        closeModal(modalId) {
            const modal = document.getElementById(modalId);
            if (modal) {
                modal.classList.remove("show");
                modal.style.display = "none";
                document.body.classList.remove("modal-open");

                const backdrop = document.querySelector(".modal-backdrop");
                if (backdrop) {
                    backdrop.remove();
                }
                this.resetForm();
            }
        },
        async updatePermission() {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            try {
                const response = await axios.put(
                    `http://127.0.0.1:8000/api/permissions/update/${this.currentPermissionId}`,
                    {
                        permission_name: this.permissionName,
                        description: this.permissionDescription,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                if (response.data.status) {
                    Swal.fire({ icon: "success", title: "Success", text: response.data.message });
                    this.fetchPermissions();
                    this.closeModal("updatePermission");
                } else {
                    Swal.fire({ icon: "error", title: "Error", text: response.data.message });
                }
            } catch (error) {
                console.error("Error updating permission:", error);
                Swal.fire({ icon: "error", title: "Error", text: "An error occurred while updating the permission." });
            }
        },
        async deletePermission(permission_id) {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            const confirmDelete = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            });
            if (confirmDelete.isConfirmed) {
                try {
                    const response = await axios.delete(`http://127.0.0.1:8000/api/permissions/delete/${permission_id}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (response.data.status) {
                        Swal.fire({ icon: "success", title: "Deleted!", text: response.data.message });
                        this.fetchPermissions();
                    } else {
                        Swal.fire({ icon: "error", title: "Error", text: response.data.message });
                    }
                } catch (error) {
                    console.error("Error deleting permission:", error);
                    Swal.fire({ icon: "error", title: "Error", text: "An error occurred while deleting the permission." });
                }
            }
        },
        resetForm() {
            this.permissionName = "";
            this.permissionDescription = "";
            this.currentPermissionId = null;
        },
        handleAuthError(error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "Unauthorized",
                    text: "Your session has expired. Please log in again.",
                });
            }
        },
    },
};
</script>

<style scoped>
/* Add any additional styles you need here */
</style>