<template>
    <div class="container-fluid">
        <h2>Mentors</h2>
        <div class="d-flex mb-2">
            <button type="button" class="btn bg-custom-color ms-auto" data-bs-toggle="modal"
                data-bs-target="#addMentor">
                Add Mentor
            </button>
        </div>

        <!-- Modal for Adding Mentor -->
        <div class="modal fade" id="addMentor" tabindex="-1" aria-labelledby="addMentorLabel" aria-hidden="true"
            ref="addMentorModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addMentorLabel">Add Mentor</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="resetForm"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="storeMentor">
                            <div>
                                <label for="expertise_area" class="text-lg font-medium">Expertise Area</label>
                                <div class="my-3">
                                    <input type="text" placeholder="Enter Expertise Area" v-model="expertiseArea"
                                        class="form-control" />
                                    <div v-if="errors.expertise_area" class="text-danger">{{ errors.expertise_area }}
                                    </div>
                                </div>

                                <label for="experience_years" class="text-lg font-medium">Experience Years</label>
                                <div class="my-3">
                                    <input type="number" placeholder="Enter Experience Years" v-model="experienceYears"
                                        class="form-control" />
                                    <div v-if="errors.experience_years" class="text-danger">{{ errors.experience_years
                                        }}</div>
                                </div>

                                <label for="fixed_price" class="text-lg font-medium">Fixed Price</label>
                                <div class="my-3">
                                    <input type="number" placeholder="Enter Fixed Price" v-model="fixedPrice"
                                        class="form-control" />
                                    <div v-if="errors.fixed_price" class="text-danger">{{ errors.fixed_price }}</div>
                                </div>

                                <label for="availability" class="text-lg font-medium">Availability</label>
                                <div class="my-3">
                                    <textarea placeholder="Enter Availability (JSON format)" v-model="availability"
                                        class="form-control"></textarea>
                                    <div v-if="errors.availability" class="text-danger">{{ errors.availability }}</div>
                                </div>

                                <label for="status" class="text-lg font-medium">Status</label>
                                <div class="my-3">
                                    <input type="text" placeholder="Enter Status" v-model="status"
                                        class="form-control" />
                                    <div v-if="errors.status" class="text-danger">{{ errors.status }}</div>
                                </div>

                                <button type="submit" class="btn bg-custom-color">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal for Updating Mentor -->
        <div class="modal fade" id="updateMentor" tabindex="-1" aria-labelledby="updateMentorLabel" aria-hidden="true"
            ref="updateMentorModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="updateMentorLabel">Update Mentor</h5>
                        <button type="button" class="btn-close" aria-label="Close"
                            @click="closeModal('updateMentor')"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="updateMentor">
                            <div>
                                <label for="expertise_area" class="text-lg font-medium">Expertise Area</label>
                                <div class="my-3">
                                    <input type="text" placeholder="Enter Expertise Area" v-model="expertiseArea"
                                        class="form-control" />
                                    <div v-if="errors.expertise_area" class="text-danger">{{ errors.expertise_area }}
                                    </div>
                                </div>

                                <label for="experience_years" class="text-lg font-medium">Experience Years</label>
                                <div class="my-3">
                                    <input type="number" placeholder="Enter Experience Years" v-model="experienceYears"
                                        class="form-control" />
                                    <div v-if="errors.experience_years" class="text-danger">{{ errors.experience_years
                                        }}</div>
                                </div>

                                <label for="fixed_price" class="text-lg font-medium">Fixed Price</label>
                                <div class="my-3">
                                    <input type="number" placeholder="Enter Fixed Price" v-model="fixedPrice"
                                        class="form-control" />
                                    <div v-if="errors.fixed_price" class="text-danger">{{ errors.fixed_price }}</div>
                                </div>

                                <label for="availability" class="text-lg font-medium">Availability</label>
                                <div class="my-3">
                                    <textarea placeholder="Enter Availability (JSON format)" v-model="availability"
                                        class="form-control"></textarea>
                                    <div v-if="errors.availability" class="text-danger">{{ errors.availability }}</div>
                                </div>

                                <label for="status" class="text-lg font-medium">Status</label>
                                <div class="my-3">
                                    <input type="text" placeholder="Enter Status" v-model="status"
                                        class="form-control" />
                                    <div v-if="errors.status" class="text-danger">{{ errors.status }}</div>
                                </div>

                                <button type="submit" class="btn bg-custom-color">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <table class="table table-striped table-bordered text-center">
            <thead class="bg-gray-50">
                <tr>
                    <th>#</th>
                    <th>Expertise Area</th>
                    <th>Experience Years</th>
                    <th>Fixed Price</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="mentors.length === 0">
                    <td colspan="6" class="text-center">No mentors found</td>
                </tr>
                <tr v-for="(mentor, index) in mentors" :key="mentor.mentor_id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ mentor.expertise_area }}</td>
                    <td>{{ mentor.experience_years }}</td>
                    <td>{{ mentor.fixed_price }}</td>
                    <td>{{ mentor.status }}</td>
                    <td>
                        <button class="btn btn-primary button-spacing"
                            @click="editMentor(mentor.mentor_id)">Edit</button>
                        <button class="btn btn-danger" @click="deleteMentor(mentor.mentor_id)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            mentors: [],
            expertiseArea: "",
            experienceYears: null,
            fixedPrice: null,
            availability: "",
            status: "",
            currentMentorId: null,
            errors: {}  // This will hold the validation errors for each field
        };
    },
    mounted() {
        this.fetchMentors();
    },
    methods: {
        async fetchMentors() {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            try {
                const response = await axios.get("http://127.0.0.1:8000/api/mentors/list", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                this.mentors = response.data.status ? response.data.mentors : [];
            } catch (error) {
                console.error("Error fetching mentors:", error);
                this.mentors = [];
                this.handleAuthError(error);
            }
        },
        async storeMentor() {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }

            // Reset previous errors
            this.errors = {};

            try {
                const response = await axios.post(
                    "http://127.0.0.1:8000/api/mentors/store",
                    {
                        expertise_area: this.expertiseArea,
                        experience_years: this.experienceYears,
                        fixed_price: this.fixedPrice,
                        availability: JSON.parse(this.availability),
                        status: this.status,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );

                if (response.data.status) {
                    Swal.fire({ icon: "success", title: "Success", text: response.data.message });
                    this.fetchMentors(); // Reload the mentors
                    this.resetForm();
                    this.$refs.addMentorModal.click(); // Close the modal
                }
            } catch (error) {
                console.error("Error storing mentor:", error);
                if (error.response && error.response.data.errors) {
                    this.errors = error.response.data.errors;
                }
            }
        },
        async editMentor(mentorId) {
            try {
                const response = await axios.get(`http://127.0.0.1:8000/api/mentors/edit/${mentorId}`);
                const mentor = response.data.mentor;

                this.currentMentorId = mentor.mentor_id;
                this.expertiseArea = mentor.expertise_area;
                this.experienceYears = mentor.experience_years;
                this.fixedPrice = mentor.fixed_price;
                this.availability = JSON.stringify(mentor.availability); // Assuming availability is a JSON string
                this.status = mentor.status;

                this.$refs.updateMentorModal.show(); // Show the edit modal
            } catch (error) {
                console.error("Error editing mentor:", error);
            }
        },
        async updateMentor() {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }

            try {
                const response = await axios.put(
                    `http://127.0.0.1:8000/api/mentors/update/${this.currentMentorId}`,
                    {
                        expertise_area: this.expertiseArea,
                        experience_years: this.experienceYears,
                        fixed_price: this.fixedPrice,
                        availability: JSON.parse(this.availability),
                        status: this.status,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );

                if (response.data.status) {
                    Swal.fire({ icon: "success", title: "Success", text: response.data.message });
                    this.fetchMentors(); // Reload mentors
                    this.closeModal('updateMentor');
                }
            } catch (error) {
                console.error("Error updating mentor:", error);
                if (error.response && error.response.data.errors) {
                    this.errors = error.response.data.errors;
                }
            }
        },
        async deleteMentor(mentorId) {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }

            try {
                const response = await axios.delete(
                    `http://127.0.0.1:8000/api/mentors/delete/${mentorId}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );

                if (response.data.status) {
                    Swal.fire({ icon: "success", title: "Deleted", text: response.data.message });
                    this.fetchMentors(); // Reload mentors list
                }
            } catch (error) {
                console.error("Error deleting mentor:", error);
                Swal.fire({ icon: "error", title: "Error", text: "Failed to delete mentor." });
            }
        },
        resetForm() {
            this.expertiseArea = '';
            this.experienceYears = null;
            this.fixedPrice = null;
            this.availability = '';
            this.status = '';
            this.errors = {};
        },
        closeModal(modalId) {
            const modal = document.getElementById(modalId);
            const bootstrapModal = new bootstrap.Modal(modal);
            bootstrapModal.hide();
        },
        handleAuthError(error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({ icon: 'error', title: 'Unauthorized', text: 'Please log in again.' });
            }
        }
    }
};
</script>

<style scoped>
/* Add your custom CSS styling here */
</style>
