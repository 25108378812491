<template>
  <header class="site-header header-style-3 mobile-sider-drawer-menu">
    <div class="sticky-header main-bar-wraper navbar-expand-lg shadow">
      <div class="main-bar">
        <div class="container-fluid clearfix">
          <div class="logo-header">
            <div class="logo-header-inner logo-header-one">
              <a href="/" class="h-100 w-100">
                <img src="../../assets/images/logos/mw-logo.svg" alt="" class="h-logo h-100 w-100" />
              </a>
            </div>
          </div>

          <!-- NAV Toggle Button -->
          <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button"
            class="navbar-toggler collapsed">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar icon-bar-first"></span>
            <span class="icon-bar icon-bar-two"></span>
            <span class="icon-bar icon-bar-three"></span>
          </button>

          <!-- MAIN Nav -->
          <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
            <ul class="nav navbar-nav">
              <li class="has-child">
                <router-link exact-active-class="active" to="/">Home</router-link>
              </li>
              <li class="has-child">
                <router-link exact-active-class="active" to="/about">About Us</router-link>
              </li>
              <li class="has-child">
                <router-link exact-active-class="active" to="/mentors">Mentors</router-link>
              </li>
              <li class="has-child">
                <router-link exact-active-class="active" to="/contact">Contact Us</router-link>
              </li>
            </ul>
          </div>

          <!-- Header Right Section -->
          <div class="extra-nav header-2-nav">
            <div class="extra-cell">
              <div v-if="!isLoggedIn" class="header-nav-btn-section">
                <div class="twm-nav-btn-left">
                  <a class="twm-nav-sign-up" data-bs-toggle="modal" href="#sign_up_popup" role="button">
                    <i class="feather-log-in"></i> Sign Up
                  </a>
                </div>
              </div>

              <div v-if="!isLoggedIn" class="header-nav-btn-section">
                <div class="twm-nav-btn-left">
                  <a class="twm-nav-sign-up" data-bs-toggle="modal" href="#sign_up_popup2" role="button">
                    <i class="feather-log-in"></i> Sign In
                  </a>
                </div>
              </div>
            </div>
            <div v-if="isLoggedIn" class="extra-cell">
              <div class="header-nav-btn-section">
                <div class="twm-nav-btn-left">
                  <a v-if="userRole" :href="`/dashboard/${userRole}`" class="twm-nav-sign-up" role="button">
                    <i class="feather-user"></i> Dashboard
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- SITE Search -->
      <div id="search">
        <span class="close"></span>
        <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
          <input class="form-control" value="" name="q" type="search" placeholder="Type to search" />
          <span class="input-group-append">
            <button type="button" class="search-btn">
              <i class="fa fa-paper-plane"></i>
            </button>
          </span>
        </form>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const isLoggedIn = ref(false);
    const userRole = ref(null);
    const router = useRouter();
    const checkLoginStatus = () => {
      const token = localStorage.getItem("token");
      if (token) {
        isLoggedIn.value = true;
        userRole.value = localStorage.getItem("role");
      }
    };
    const logout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      isLoggedIn.value = false;
      userRole.value = null;
      router.push("/");
    };
    onMounted(() => {
      checkLoginStatus();
    });

    return {
      isLoggedIn,
      userRole,
      logout,
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.active {
  color: var(--primary-blue) !important;
}
</style>
