<template>
  <div class="sidebar">
    <TabMenu :model="items">
      <template #item="{ item, props }">
        <a v-ripple @click="handleItemClick(item)" v-bind="props.action" :class="{ active: isActive(item.route) }">
          <img v-if="item.iconBlack && !isActive(item.route)" :src="getIconPath(item.iconBlack)" alt="icon"
            class="sidebar-icon" />
          <img v-if="item.iconWhite && isActive(item.route)" :src="getIconPath(item.iconWhite)" alt="icon"
            class="sidebar-icon" />
          <span v-bind="props.label">{{ item.label }}</span>
          <template v-if="item.label === 'Account & Settings'">
            <TabMenu :model="item.subtabs">
              <template #item="{ subitem, props }">
                <a v-if="subitem" v-ripple @click="handleSubItemClick(subitem)" v-bind="props.action"
                  :class="{ active: isActive(subitem.route) }">
                  <!-- <span v-bind="props.label">{{ subitem.label }}</span> -->
                  {{ subitem.label }}
                </a>
              </template>
            </TabMenu>
          </template>
        </a>
      </template>
    </TabMenu>

    <a href="" class="faq">FAQs</a>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import TabMenu from "primevue/tabmenu";

export default {
  components: { TabMenu },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const items = ref([
      {
        label: "Dashboard",
        iconBlack: "home-icon-black.svg",
        iconWhite: "home-icon-white.svg",
        route: "/dashboard",
      },
      {
        label: "My Request",
        iconBlack: "request-icon-black.svg",
        iconWhite: "request-icon-white.svg",
        route: "/my-request",
      },
      {
        label: "My Schedule",
        iconBlack: "schedule-icon-black.svg",
        iconWhite: "schedule-icon-white.svg",
        route: "/my-schedule",
      },
      {
        label: "Session History",
        iconBlack: "session-icon-black.svg",
        iconWhite: "session-icon-white.svg",
        route: "/session-history",
      },
      {
        label: "Feedback & Ratings",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-white.svg",
        route: "/feedback-&-ratings",
      },
      {
        label: "Permissions",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-white.svg",
        route: "/permissions",
      },
      {
        label: "Roles",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-white.svg",
        route: "/roles",
      },
      {
        label: "Users",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-white.svg",
        route: "/users",
      },
      {
        label: "Mentors",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-white.svg",
        route: "/mentor-page",
      },

      {
        label: "Account & Settings",
        iconBlack: "settings-icon-black.svg",
        iconWhite: "account-icon-white.svg",
        route: "/account-&-settings",
        subtabs: [
          {
            label: "Account Settings",
            route: "/account-settings",
          },
          {
            label: "Notifications",
            route: "/notifications",
          },
        ],
      },
    ]);

    const handleItemClick = (item) => {
      const userRole = localStorage.getItem("role");
      if (item.route === "/dashboard") {
        if (userRole === "admin") {
          router.push("/dashboard/admin");
        } else if (userRole === "super_admin") {
          router.push("/dashboard/super_admin");
        } else if (userRole === "mentor") {
          router.push("/dashboard/mentor");
        } else if (userRole === "mentee") {
          router.push("/dashboard/mentee");
        } else {
          router.push("/dashboard");
        }
      } else {
        router.push(item.route);
      }
    };


    const handleSubItemClick = (subitem) => {
      router.push(subitem.route);
    };

    const isActive = (routeName) => {
      return route.path === routeName;
    };

    const getIconPath = (icon) => {
      return require(`@/assets/images/icons/${icon}`);
    };

    return {
      items,
      handleItemClick,
      handleSubItemClick,
      isActive,
      getIconPath,
    };
  },
};
</script>

<style scoped>
.sidebar {
  height: 100vh;
  margin: 40px 0px;
  border-right: 0.5px solid #a4a4a4;
}

.sidebar ::v-deep .p-tabmenu-ink-bar {
  background: #a4a4a4;
}

.sidebar ::v-deep .p-tabmenu-nav {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 12px;
  background: transparent;
}

/* unselected menu item */
.sidebar ::v-deep .p-menuitem-link {
  color: #535353;
  border: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  border-radius: 0px;
  background: transparent;
}

.sidebar ::v-deep .p-tabmenu-nav a.active {
  background-color: var(--primary-blue);
  color: white;
  text-decoration: none;
  font-weight: 500;
  /* font-size: 18px; */
  font-family: "Montserrat", sans-serif;
  border-radius: 4px;
}

.faq {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-blue);
  text-decoration: none;
  padding: 0 1rem;
}

.sidebar-icon {
  margin-right: 10px;
}
</style>
